/** @format */

import axios from "axios";
// import { config } from "process";
// https://admin.ropajo.com

const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000"
      : "https://admin.ropajo.com",
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem("userInfo"))
      config.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("userInfo")).token
      }`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default apiClient;
