import React, { useState } from "react";
import "./GuestAccount.css";
import { Link } from "react-router-dom";

import GuestDashBoard from "../GuestDashBoard/GuestDashBoard";
import GuestAccountTableRow from "../GuestAccountTableRow/GuestAccountTableRow";
import DashBoardHeader from "../../DashBoardHeader/DashBoardHeader";
import DashboardRoomProfile from "../../DashboardRoomProfile/DashboardRoomProfile";

const GuestAccountTableRowData = [
  {
    Date: "03.02.2023",
    Category: "Room",
    Bill: "90,000.00",
    Paid: "40,000.00",
    Balance: "50,000.00",
  },
  {
    Date: "03.02.2023",
    Category: "Laundry",
    Bill: "90,000.00",
    Paid: "40,000.00",
    Balance: "50,000.00",
  },
  {
    Date: "03.02.2023",
    Category: "Restaurant",
    Bill: "90,000.00",
    Paid: "40,000.00",
    Balance: "00.00",
  },
  {
    Date: "03.02.2023",
    Category: "Pool",
    Bill: "90,000.00",
    Paid: "40,000.00",
    Balance: "10,000.00",
  },
  {
    Date: "03.02.2023",
    Category: "Room",
    Bill: "90,000.00",
    Paid: "40,000.00",
    Balance: "30,000.00",
  },
  {
    Date: "03.02.2023",
    Category: "Restaurant",
    Bill: "30,000.00",
    Paid: "00.00",
    Balance: "3 0,000.00",
  },
];
const GuestAccount = () => {
  const [profileShow, setProfileShow] = useState(false);
  return (
    <div className="GuestAccount">
      {/* websection starts here */}
      <div className="GuestAccount-web">
        <div className="GuestAccount-guest-section">
          <GuestDashBoard />
        </div>

        <div className="GuestAccount-content-section">
        <div className="GuestAccount-mobile-header">
            <DashboardRoomProfile
              profileShow={profileShow}
              setProfileShow={setProfileShow}
            />
            <DashBoardHeader setProfileShow={setProfileShow} />
          </div>
          <div className="GuestAccount-content-head">
            <Link>Back to Homepage</Link>
          </div>
          <div className="GuestAccount-content-body">
            <div className="GuestAccount-title-holder">
              <p>Account </p>
            </div>
            <div>
              <div className="GuestAccount-content-table-section">
                <div className="GuestAccount-web-table-holder">
                  <table className="GuestAccount-web-table">
                    <thead>
                      <div>
                        <span>Date</span>
                        <span>Category</span>
                        <span>Bill</span>
                        <span>Paid</span>
                        <span>Balance</span>
                      </div>
                    </thead>
                    <section>
                      {GuestAccountTableRowData.map(
                        (
                          GuestAccountTableRowData,
                          index // Corrected parameter order
                        ) => (
                          <GuestAccountTableRow
                            key={index} // Always use a unique key for each mapped component
                            index={index}
                            Date={GuestAccountTableRowData.Date}
                            Category={GuestAccountTableRowData.Category}
                            Bill={GuestAccountTableRowData.Bill}
                            Paid={GuestAccountTableRowData.Paid}
                            Balance={GuestAccountTableRowData.Balance}
                          />
                        )
                      )}
                    </section>

                    <tfoot>
                      <div>
                        <span></span>
                        <span className="">
                          <h3 className="GuestAccount-total">TOTAL</h3>
                        </span>
                        <span className="GuestAccount-bottom-cell">
                          <h3>BILL: </h3>
                          <h3 className="GuestAccount-total-amount bill-red">
                            216,500.00
                          </h3>
                        </span>
                        <span className="GuestAccount-bottom-cell">
                          <h3>PAID: </h3>
                          <h3 className="GuestAccount-total-amount paid-green">
                            216,500.00
                          </h3>
                        </span>
                        <span className="GuestAccount-bottom-cell">
                          <h3>BALANCE: </h3>
                          <h3 className="GuestAccount-total-amount">
                            216,500.00
                          </h3>
                        </span>
                      </div>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestAccount;
