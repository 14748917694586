/** @format */

import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../apiClient";

// fech all payments
export const useGetPaymentsQuery = () => {
  return useQuery({
    queryKey: ["payments"],
    queryFn: async () => (await apiClient.get("payment/all-payments")).data,
  });
};

// fetch payment
export const useGetPaymentDetailQuery = (userUniqueId) => {
  return useQuery({
    queryKey: ["payment", userUniqueId],
    queryFn: async () => (await apiClient.get(`payment/${userUniqueId}`)).data,
  });
};

// Hook for making a payment
export const useMakePaymentMutation = () => {
  return useMutation({
    mutationFn: async (paymentDetails) => {
      const response = await apiClient.post(
        `payment/make-payment`,
        paymentDetails
      );
      return response.data;
    },
    onError: (error) => {
      console.error("Error making payment:", error);
    },
  });
};

// user payment
export const useFetchPaymentsByUserId = (id) => {
  return useQuery({
    queryKey: ["payments", id],
    queryFn: async () => {
      const response = await apiClient.get(`payment/user-payments/${id}`);

      return response.data;
    },
  });
};

// Hook for updating a payment
// export const useUpdatePaymentMutation = () => {
//   return useMutation({
//     mutationFn: async (paymentDetails) => {
//       const { paymentId, ...payload } = paymentDetails;

//       const response = await apiClient.put(
//         `payment/make-payment/${paymentId}`,
//         payload
//       );
//       return response.data;
//     },
//     onError: (error) => {
//       console.error("Error updating payment:", error);
//     },
//   });
// };
