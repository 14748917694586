import React from "react";
import "./Receipt.css";
import hat from '../../assets/cap-brown.svg';
import logo from "../../assets/mob-logo.svg";

const Receipt = () => {
  return (
    <div className="Receipt">
      <header className="Receipt-header">
        <div className="Receipt-container">
          <img src={logo} alt="" />
          <div className="Receipt-head-container">
            <h1>INVOICE</h1>
          </div>
        </div>
      </header>
      <section className="Receipt-address">
        <div className="Receipt-address-container">
          <div className="Receipt-address-container-left">
            <p>Lobi Quarters, Makurdi Benue State.</p>
            <p>+2347055443322</p>
            <p>inquiries@ropajo.com</p>
            <p>www.ropajo.com</p>
          </div>
          <div className="Receipt-address-container-right">
            <p>Bill to:</p>
            <h2>Peter Ajali A.</h2>
            <div className="Receipt-address-container-right-profile">
              <p>TL Osborn Ln, Soweto</p>
              <p>South Africa.</p>
              <p>+211755443322</p>
              <p>client@gmail.com</p>
            </div>
          </div>
        </div>
      </section>
      <body className="Receipt-body">
        <section className="Receipt-table">
          <div className="Receipt-table-head">
            <p>DATE</p>
            <p>DESCRIPTION</p>
            <p>UNIT COST</p>
            <p>QUANTITY</p>
            <p>AMOUNT</p>
          </div>
          <div className="Receipt-table-body">
            <div className="Receipt-table-row">
              <p>23:14:2024</p>
              <p>Supreme Super Executive 1</p>
              <p>170,000.00</p>
              <p>1 Night</p>
              <p>170,000.00</p>
            </div>
            <div className="Receipt-table-row">
              <p>23:14:2024</p>
              <p>Supreme Super Executive 1</p>
              <p>170,000.00</p>
              <p>1 Night</p>
              <p>170,000.00</p>
            </div>
          </div>
        </section>
      </body>
      <footer className="Receipt-footer">
        <section className="Receipt-footer-left">
          <div className="Receipt-policy">
            <h2 className="Receipt-policy-header">
              RETURN POLICY, TERMS AND CONDITIONS
            </h2>
            <p className="Receipt-policy-text">
              ksnfjkdnv vcjdvbn djkcvndjkvcbd cjkdnvdjvnd cdj schbd jncd
              vcvjdvcbndj cdjhvbdj dcb dnvc ddsc dscnd cnd cmdn cnmd cmndc dcn
              dcvnd cnn sdnc dsn c dc ndc cdnb cdbc bdnc.
            </p>
          </div>
          <div className="Receipt-bank-detail">
            <p>ROPAJO HOTELS AND APPARTMENTS</p>
            <p>0099887766</p>
            <p>ZENITH BANK PLC</p>
          </div>
        </section>
        <section className="Receipt-footer-right">
          <div className="Receipt-acc">
            <div className="Receipt-acc-board">
              <div className="Receipt-acc-title">
                <p>TOTAL</p>
                <p>DEPOSIT</p>
                <p>BALANCE</p>
              </div>
              <div className="Receipt-acc-amount">
                <p>170,000.00</p>
                <p>100,000.00</p>
                <p>70,000.00</p>
              </div>
            </div>
          </div>
          <div className="Receipt-hat-container">
            <img src={hat} alt="" />
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Receipt;
