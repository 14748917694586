/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import play_btn from "../../../assets/play-icon.svg";

import { useGetGuestDetailsQuery } from "../../../hooks/guest";
import { useFetchPaymentsByUserId } from "../../../hooks/payment";
import { useGetRoomDetailsQuery } from "../../../hooks/room";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import "./GuestDashBoardRoom.css";

// DateFormatter Component
const DateFormatter = ({ isoDate }) => {
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
  };

  return <>{formatDate(isoDate)}</>;
};

const GuestDashBoardRoom = () => {
  const [identifier, setIdentifier] = useState(null);
  const [index, setIndex] = useState(null);
  const { data } = useGetRoomDetailsQuery(identifier);

  const { data: guest } = useGetGuestDetailsQuery(index || "");

  const { data: payment } = useFetchPaymentsByUserId(index);

  useEffect(() => {
    const hash = window.location.hash;
    const queryString = hash.split("?")[1];
    const params = new URLSearchParams(queryString);
    const id = params.get("id");
    const index = params.get("index");

    const userUniqueId = params.get("userUniqueId");

    setIndex(index);

    // Set identifier to either `id` or `userUniqueId`
    setIdentifier(id || userUniqueId);
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);

  const handlePlayButtonClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div className="GuestDashBoardRoom">
        <div className="GuestDashBoardRoom-web">
          <div className="GuestDashBoardRoom-profile-section">
            <ReceptionistDashboard />
          </div>

          <div className="GuestDashBoardRoom-content-section">
            <div className="GuestDashBoardRoom-content-head">
              <Link to="/">Back to Homepage</Link>
            </div>
            <div className="GuestDashBoardRoom-content-body">
              <div className="GuestDashBoardRoom-option-details">
                <div className="GuestDashBoardRoom-user-detail">
                  {/* User details can be added here if needed */}
                  <p>{guest?.fullName || ""}</p>
                  <p>{identifier}</p>
                </div>
                <div className="GuestDashBoardRoom-recep-detail">
                  <Link
                    to={`/UpdateGuestPayment?id=${identifier}&&questId=${index}`}
                  >
                    <button>Payments</button>
                  </Link>
                  <Link to="/Receipt">
                    <button>Receipt</button>
                  </Link>
                  <Link to="/ManageGuest">
                    <button>Checkout</button>
                  </Link>
                </div>
              </div>
              <div className="GuestDashBoardRoom-account-title">
                <h2>Accounts</h2>
              </div>

              <div className="GuestDashBoardRoom-center-holder">
                <div className="GuestDashBoardRoom-content-table-section">
                  <main className="GT-container">
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={handlePlayButtonClick}
                          />
                          <p>Room:</p>
                        </div>
                      </div>
                      {isVisible && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Room</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p> {/* Added balance column */}
                            </div>
                            {data && data.length > 0 ? (
                              data
                                .filter((room) => room.type === "Checkin")
                                .map((item, index) => {
                                  const matchingPayment = payment?.find(
                                    (pay) =>
                                      pay.category === "Room" &&
                                      pay.comment.includes(`id:${item._id}`)
                                  );

                                  const balance = matchingPayment
                                    ? matchingPayment.bill -
                                      matchingPayment.paid
                                    : 0; // Calculate balance

                                  return matchingPayment ? (
                                    <div key={index} className="GT-Table-row">
                                      <p className="first-item">
                                        {item?.name || "N/A"}
                                      </p>
                                      <p>
                                        <DateFormatter
                                          isoDate={item?.createdAt || ""}
                                        />
                                      </p>
                                      <p>{matchingPayment?.bill || "N/A"}</p>
                                      <p>{matchingPayment?.paid || "N/A"}</p>
                                      <p>{balance || "N/A"}</p>{" "}
                                      {/* Display balance */}
                                    </div>
                                  ) : (
                                    <div key={index} className="GT-Table-row">
                                      <p className="first-item">
                                        {item?.name || "N/A"}
                                      </p>
                                      <p>
                                        <DateFormatter
                                          isoDate={item?.createdAt || ""}
                                        />
                                      </p>
                                      <p>{item?.price || "N/A"}</p>
                                      <p></p>
                                      <p></p>
                                    </div>
                                  );
                                })
                            ) : (
                              <div
                                style={{ color: "red", textAlign: "center" }}
                              >
                                No Room Assigned
                              </div>
                            )}
                          </section>
                        </div>
                      )}
                    </div>
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible1 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible1(!isVisible1)}
                          />
                          <p>Laundry:</p>
                        </div>
                      </div>
                      {isVisible1 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment.map((items) =>
                                items.category === "Laundry" ? (
                                  <div key={items._id} className="GT-Table-row">
                                    <p className="first-item">
                                      {items.comment}
                                    </p>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Date:</p>
                                      <p>
                                        <DateFormatter
                                          isoDate={items?.updatedAt || ""}
                                        />
                                      </p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bill:</p>
                                      <p>{items.bill}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Paid:</p>
                                      <p>{items.paid}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bal:</p>
                                      <p>{items.bill - items.paid}</p>{" "}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                          </section>
                        </div>
                      )}
                    </div>
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible2 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible2(!isVisible2)}
                          />
                          <p>Restaurant:</p>
                        </div>
                      </div>
                      {isVisible2 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment.map((items) =>
                                items.category === "Restaurant" ? (
                                  <div key={items._id} className="GT-Table-row">
                                    <p className="first-item">
                                      {items.comment}
                                    </p>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Date:</p>
                                      <p>
                                        <DateFormatter
                                          isoDate={items?.updatedAt || ""}
                                        />
                                      </p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bill:</p>
                                      <p>{items.bill}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Paid:</p>
                                      <p>{items.paid}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bal:</p>
                                      <p>{items.bill - items.paid}</p>{" "}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                          </section>
                        </div>
                      )}
                    </div>
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible3 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible3(!isVisible3)}
                          />
                          <p>Bar:</p>
                        </div>
                      </div>
                      {isVisible3 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment.map((items) =>
                                items.category === "Bar" ? (
                                  <div key={items._id} className="GT-Table-row">
                                    <p className="first-item">
                                      {items.comment}
                                    </p>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Date:</p>
                                      <p>
                                        <DateFormatter
                                          isoDate={items?.updatedAt || ""}
                                        />
                                      </p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bill:</p>
                                      <p>{items.bill}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Paid:</p>
                                      <p>{items.paid}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bal:</p>
                                      <p>{items.bill - items.paid}</p>{" "}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                          </section>
                        </div>
                      )}
                    </div>
                    <div className="GT-table-option">
                      <div className="GT-option-Button">
                        <div>
                          <img
                            src={play_btn}
                            alt="Play Button"
                            className={`${
                              isVisible4 ? "clicked-class" : "clicked-back"
                            }`}
                            onClick={() => setIsVisible4(!isVisible4)}
                          />
                          <p>Others:</p>
                        </div>
                      </div>
                      {isVisible4 && (
                        <div className="GT-option-Body">
                          <section className="GT-table">
                            <div className="GT-Table-row table-row-header">
                              <p>Items</p>
                              <p>Date</p>
                              <p>Bill</p>
                              <p>Paid</p>
                              <p>Bal</p>
                            </div>
                            {payment &&
                              payment.map((items) =>
                                items.category === "Others" ? (
                                  <div key={items._id} className="GT-Table-row">
                                    <p className="first-item">
                                      {items.comment}
                                    </p>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Date:</p>
                                      <p>
                                        <DateFormatter
                                          isoDate={items?.updatedAt || ""}
                                        />
                                      </p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bill:</p>
                                      <p>{items.bill}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Paid:</p>
                                      <p>{items.paid}</p>
                                    </div>
                                    <div className="GuestDashBoardRoom-mob-row">
                                      <p>Bal:</p>
                                      <p>{items.bill - items.paid}</p>{" "}
                                      {/* Display balance */}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                          </section>
                        </div>
                      )}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestDashBoardRoom;
