import React, { useContext, useEffect, useState } from "react";
import "./GuestAccessPage.css";
import GuestDashBoard from "../GuestDashBoard/GuestDashBoard";
import { Link, useNavigate } from "react-router-dom";
import DashBoardHeader from "../../DashBoardHeader/DashBoardHeader";
import DashboardRoomProfile from "../../DashboardRoomProfile/DashboardRoomProfile";
import { Store } from "../../../Store";
import { useGetGuestDetailsQuery } from "../../../hooks/guest";

const GuestAccessPage = () => {
  const navigate = useNavigate();
  const [profileShow, setProfileShow] = useState(false);
  const {
    state: { userInfo },
  } = useContext(Store);

  // Redirect if userInfo or userInfo._id is expired
  useEffect(() => {
    if (!userInfo || !userInfo._id) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  const {
    data: guest,
    isLoading,
    isError,
  } = useGetGuestDetailsQuery(userInfo?._id || "");

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading guest details</div>;
  }

  return (
    <div>
      <div className="GuestAccessPage-web">
        <div className="GuestAccessPage-guest-section">
          <GuestDashBoard name={guest ? guest.fullName.toUpperCase() : ""} />
        </div>

        <div className="GuestAccessPage-content-section">
          <div className="GuestAccessPage-content-head">
            <Link to="/">Back to Homepage</Link>
          </div>
          <div className="GuestAccessPage-mobile-header">
            <DashboardRoomProfile
              profileShow={profileShow}
              setProfileShow={setProfileShow}
            />
            <DashBoardHeader setProfileShow={setProfileShow} />
          </div>
          <div className="GuestAccessPage-content-body">
            <p>{/*Account*/} </p>
            <div>
              <div className="GuestAccessPage-content-table-section">
                {/* table */}
                <div className="access-card">
                  <div className="access-card-box">
                    <h2>{guest ? guest.fullName : " "}</h2>
                    <p>Unique Number: {guest ? guest.userUniqueId : ""}</p>
                  </div>
                  <div className="access-card-box double-box">
                    <div>
                      <h2>WIFI</h2>
                      <div>
                        <p>Username: Ropajo</p>
                        <p>Password: Dragon234</p>
                      </div>
                    </div>
                    <div>
                      <h2>Netflix</h2>
                      <div>
                        <p>Username: ropajohotels2@gmail.com</p>
                        <p>Password: Dragonfly</p>
                      </div>
                    </div>
                  </div>
                  <div className="access-card-box access-contact-box">
                    <h2>Emergency</h2>
                    <p>08159542159</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestAccessPage;
